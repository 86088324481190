export const fields = [
    { key: 'index', label: "No", _style:'min-width:30px'},
    { key: 'select', label: "", _style:'min-width:30px' },
    { key: 'image', label: "Icon", _style:'min-width:100px' },
    { key: 'name', label: "Nama SHP", _style:'min-width:100px' },
    { key: 'type', label: "Tipe SHP", _style:'min-width:100px' },
    { key: 'category', label: "Category", _style:'min-width:100px' },
    { key: 'color', label: "Warna", _style:'min-width:100px' },
    { key: 'default', label: "Default", _style:'min-width:100px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
